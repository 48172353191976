import React from 'react';
import { Link } from 'gatsby';
import { FullContainer, ThemeContext } from 'Common';
import { HeroSection } from 'Theme/styles';
import HeroBG from 'Static/background/hero.jpg';
import { Container, Row, Col } from 'reactstrap';

const Hero = () => (
  <ThemeContext.Consumer>
    {({ theme }) => (
      <FullContainer>
        <HeroSection className="text-md-center mx-auto" bg={HeroBG}>
          <Container>
            <Row>
              <Col>
                <h1>Weight Loss Made Easy</h1>
                <p>Your health and wellness is our mission</p>
                <Link to="/contact" className="btn btn-primary mt-3">
                  Get In Touch
                </Link>
              </Col>
            </Row>
          </Container>
        </HeroSection>
      </FullContainer>
    )}
  </ThemeContext.Consumer>
);

export { Hero };
