import React from 'react';
import { Link, graphql, StaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import { FullContainer, ThemeContext, CardProduct, CardPost } from 'Common';
import {
  Button,
  Collapse,
  Jumbotron,
  Container,
  Row,
  Col,
  H1,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  Card,
  CardImg,
  CardImgOverlay,
  CardText,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardDeck
} from 'reactstrap';
import { Wrapper, Flex, BrandedText } from './styles';

const Services = () => (
  <StaticQuery
    query={graphql`
      query ServicesQuery {
        allMarkdownRemark(
          filter: {
            fileAbsolutePath: { glob: "**/src/pages/services/**/*.md" }
          }
          sort: { order: DESC, fields: [frontmatter___date] }
          limit: 1000
        ) {
          edges {
            node {
              excerpt(pruneLength: 80)
              id
              timeToRead
              frontmatter {
                title
                date(formatString: "MMM DD, YYYY")
                path
                thumbnail {
                  childImageSharp {
                    fluid(maxWidth: 800, maxHeight: 600) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => (
      <FullContainer>
        <Container className="py-5">
          <Row>
            {data.allMarkdownRemark.edges.map(service => (
              <Col
                key={service.node.id}
                xs="12"
                sm="12"
                md="4"
                lg="4"
                className="service-card"
              >
                <CardProduct {...service} />
              </Col>
            ))}
          </Row>
        </Container>
      </FullContainer>
    )}
  />
);

export { Services };

// import config from 'Data';
// import React from 'react';
// import styled from 'styled-components';
// import { StaticQuery, graphql } from 'gatsby';
// import Img from 'gatsby-image';
// import { Layout, Head, PageTitle, CardProduct } from 'Common';

// import {
//   Button,
//   Collapse,
//   Jumbotron,
//   Container,
//   Row,
//   Col,
//   Badge,
//   Dropdown,
//   DropdownItem,
//   DropdownMenu,
//   DropdownToggle,
//   Nav,
//   Navbar,
//   NavbarBrand,
//   NavbarToggler,
//   NavItem,
//   NavLink,
//   UncontrolledDropdown,
//   Card,
//   CardImg,
//   CardImgOverlay,
//   CardText,
//   CardBody,
//   CardTitle,
//   CardSubtitle,
//   CardDeck
// } from 'reactstrap';

// const Services = () => (
//   <StaticQuery
//     query={graphql`
//       query ServicesQuery {
//         allMarkdownRemark(
//           filter: {
//             fileAbsolutePath: { glob: "**/src/pages/services/**/*.md" }
//           }
//           sort: { order: DESC, fields: [frontmatter___date] }
//           limit: 20
//         ) {
//           edges {
//             node {
//               excerpt(pruneLength: 80)
//               id
//               timeToRead
//               frontmatter {
//                 title
//                 author
//                 author_avatar {
//                   childImageSharp {
//                     fluid(maxWidth: 700, maxHeight: 400) {
//                       ...GatsbyImageSharpFluid
//                     }
//                   }
//                 }
//                 date(formatString: "MMM DD, YYYY")
//                 path
//                 thumbnail {
//                   childImageSharp {
//                     fluid(maxWidth: 700, maxHeight: 400) {
//                       ...GatsbyImageSharpFluid
//                     }
//                   }
//                 }
//               }
//             }
//           }
//         }
//       }
//     `}
//     render={data => (
//       <Container className="my-5">
//         <Row>
//           {data.allMarkdownRemark.edges.map(service => (
//             <Col
//               key={service.node.id}
//               xs="12"
//               sm="6"
//               md="6"
//               lg="3"
//               className="mb-3"
//             >
//               <CardProduct {...service} />
//             </Col>
//           ))}
//         </Row>
//       </Container>
//     )}
//   />
// );

// export default Services;
