import styled from 'styled-components';

export const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 380px) {
    flex-direction: column;
  }
`;

export const BrandedText = styled.span`
  color: #b4a589;

  ${({ theme }) => theme === 'dark'
    && `
    color: #ff0000;
    `};
`;

export const WomenCategory = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  min-height: 300px;
  background-image: url("./background/tower.jpg");
  background-size: cover;
  background-position: center center;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    min-height: 100%;
    background-image: linear-gradient(
      35deg,
      rgba(0, 0, 0, 0.9) 0,
      transparent 70%
    );
  }
`;
