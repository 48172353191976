import config from 'Data';
import React from 'react';
import { Hero, Services } from 'Components/LandingPage';
import { Layout, Head, Subscribe, Callout } from 'Common';

const IndexPage = () => (
  <Layout>
    <Head type="Organization">{config.legalName}</Head>
    <Hero />
    <Services />
    <Callout />
    {/* <Subscribe /> */}
  </Layout>
);

export default IndexPage;
