import styled from 'styled-components';

export const Flex = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 380px) {
    flex-direction: column;
  }
`;

export const FullContainer = styled.div`
  width: 100%;
  padding: 2rem 0;
`;

export const BrandedText = styled.span`
  color: #b4a589;

  ${({ theme }) => theme === 'dark'
    && `
    color: #ff0000;
    `};
`;
